import React, { Component, useEffect, useRef } from "react"
import Slider from "react-slick"
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css"
import "./PreApplicationSlider.scss"
import { Container, Button, Col, Row, Form, Dropdown } from "react-bootstrap"
import SimpleMaskMoney from "../../../../node_modules/simple-mask-money/lib/simple-mask-money.js"

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  componentDidMount() {
    const args = {
      afterFormat(e) {
        console.log("afterFormat", e)
      },
      allowNegative: false,
      beforeFormat(e) {
        console.log("beforeFormat", e)
      },
      negativeSignAfter: false,
      prefix: "",
      suffix: "",
      fixed: true,
      fractionDigits: 2,
      decimalSeparator: ".",
      thousandsSeparator: ",",
      cursor: "move",
    }
    const input = SimpleMaskMoney.setMask("#amountInput", args)

    // This method return value of your input in format number to save in your database
    input.formatToNumber()
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  updateDropDown(e) {
    document.getElementById("dropdown-basic").innerHTML = e.substr(1)
  }
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: false,
      swipe: false,
      arrows: false,
    }

    // configuration

    return (
      <div className=" pre-application-slider ">
        <Slider ref={c => (this.slider = c)} {...settings}>
          <Container className="">
            <h1 className="h1-header pt-4  text-white text-center">
              How much would you like to borrow?
            </h1>
            <Row className="text-center mt-5">
              <Col className="text-right">
                <Form.Control
                  type="text"
                  className="w-50 ml-auto text-center"
                  placeholder="$"
                  id="amountInput"
                  defaultValue="1000"
                />
              </Col>

              <Col className="text-left">
                <Button
                  onClick={this.next}
                  variant="primary"
                  className="w-25 py-2"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Container>
          <Container className="mb-5">
            <h1 className="h1-header pt-4  text-white text-center">
              What do you need it for?
            </h1>
            <Row className="text-center mt-5">
              <Col className="text-right">
                <Button
                  onClick={this.previous}
                  variant="primary"
                  className="w-50 py-2 prev-btn"
                >
                  Prev
                </Button>
              </Col>
              <Col className="text-right">
                <Dropdown onSelect={this.updateDropDown}>
                  <Dropdown.Toggle
                    defaultValue=""
                    variant="success"
                    id="dropdown-basic"
                  >
                    Select one
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#Car Repair">Car Repair</Dropdown.Item>
                    <Dropdown.Item href="#Medical Bills">
                      Medical Bills
                    </Dropdown.Item>
                    <Dropdown.Item href="#Home Improvements">
                      Home Improvements
                    </Dropdown.Item>
                    <Dropdown.Item href="#Vacation">Vacation</Dropdown.Item>
                    <Dropdown.Item href="#Other Emergencies">
                      Other Emergencies
                    </Dropdown.Item>
                    <Dropdown.Item href="#Others...">Others...</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>

              <Col className="text-left">
                <Button
                  onClick={this.next}
                  variant="primary"
                  className="w-50 py-2"
                >
                  Start Application
                </Button>
              </Col>
            </Row>
          </Container>
        </Slider>{" "}
        {/*
        <div style={{ textAlign: "center" }}>
          <button className="button" onClick={this.previous}>
            Previous
          </button>
          <button className="button" onClick={this.next}>
            Next
          </button>
        </div> */}
      </div>
    )
  }
}

import React from "react"
import { Container, Button, Col, Row, Accordion, Card } from "react-bootstrap"

import "./styles/alabama-state-page.scss"
import PreApplicationSlider from "../components/Extras/PreApplicationSlider/PreApplicationSlider"

const SecondPage = () => (
  <Container fluid className=" home-container p-0 state-page-al">
    <Container fluid className="type-1-bg">
      <Container fluid className="home-slider">
        <Container
          fluid="xl"
          className="home-slider-container d-flex flex-column "
        >
          <h1 className="h1-header text-white pt-4 ">Alabama Rates</h1>
          <div className="my-auto">
            <PreApplicationSlider />
          </div>
        </Container>
      </Container>
    </Container>
    <Container fluid className="py-xl-6">
      <Container
        fluid="xl"
        className="state-page-container d-flex flex-column "
      >
        <Row>
          <Col className="text-center">
            <h1 className="h1-header pt-4 ">Alabama</h1>
            <h3 className="h3-sub-header text-center">
              Jump Loans is Proud to Offer Lines of Credit in Alabama!
            </h3>
            <p>
              There’s a lot to celebrate when it comes to Alabama. The “Rocket
              City” of Huntsville boasts the wonders of space exploration while
              the state’s largest city, Birmingham, is a national leader in
              green spaces and was pivotal in America’s Civil Rights Movement.
              Auburn is a college town like no other and Gulf Shores and Orange
              Beach offer majestic sunset beach views and fun-in-the-sun
              swimming in the Gulf of Mexico. Now that Jump Loans is available
              in your state there’s even more reason to love Alabama!
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="state-datails">
            <h3 style={{ margin: 0, marginTop: "3px" }}>$400 to $1,100</h3>
            <p style={{ margin: 0, marginTop: "3px" }}>
              <strong>Prepayment available: </strong> Yes
            </p>
            <p style={{ margin: 0, marginTop: "3px" }}>
              <strong>APR Range: </strong> Jump Loans offers a competitive APR
              based on a daily periodic interest rate.
            </p>
            <p style={{ margin: 0, marginTop: "3px" }}>
              <strong>Late Fees: </strong> XXX
            </p>
            <p>
              As of 2020, Jump Loans became a state-licensed, line of credit
              lender in the state of Alabama. We follow all applicable laws in
              Alabama.
            </p>
            <p>
              Lines of credit in Alabama are available from Jump Loans (
              <a href="https://www.linkedin.com/company/octanesystems/about/">
                a proud member of Hive Financial Systems)
              </a>{" "}
              in amounts from $400 to $1,100 based on your qualification. These
              lines of credit are paid in regular payments over xx based on your
              selection of terms in either weekly, bi-weekly, or monthly
              payments with finance fees calculated daily. Early line of credit
              repayment reduces overall finance fees. Late payments accrue
              additional finance fees and charges.
            </p>
            <h3>What you need to know:</h3>
            <ul>
              <li>
                Your minimum payment will change, based on the principal (that’s
                the amount of money you’ve withdrawn from your line of credit).{" "}
              </li>
              <li>
                You can only draw funds that remain unused in your line of
                credit. Available funds will fluctuate based on how much you’ve
                withdrawn and what you’ve repaid.{" "}
              </li>
              <li>
                You don’t have to reapply to access funds that remain in your
                line of credit. Just pay off the amount you’ve withdrawn in full
                and then you can borrow more.{" "}
              </li>
              <li>
                You will pay interest on the funds you've withdrawn, starting
                the first day you withdraw them.{" "}
              </li>
              <li>
                If you make only the minimum payment on your line of credit, you
                will pay a greater amount of interest in the long run than if
                you repay your line of credit more quickly.{" "}
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
    <Container fluid className=" py-xl-6">
      <Container
        fluid="xl"
        className="state-page-container d-flex flex-column "
      >
        <Row>
          <Col xs={12} className="img-container bg-2 my-2">
            <p className="placeholder-text"> Chart Here </p>
          </Col>
        </Row>
        <Row>
          <Col className="">
            <h1 className="h1-header pt-5 ">
              FAQ's for a Alabama line of credit
            </h1>
            <Accordion className="pt-3" defaultActiveKey="0">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  What is the term of a line of credit in Alabama?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    Lines of credit in Alabama must fall within xx in duration.{" "}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  How do I repay my Alabama line of credit?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    An Alabama line of credit breaks the total finance charges
                    into smaller, weekly, bi-weekly, or monthly payments to be
                    paid over an agreed-upon timeframe between Jump Loans and
                    the borrower.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  How do I repay my Alabama line of credit?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    An Alabama line of credit breaks the total finance charges
                    into smaller, weekly, bi-weekly, or monthly payments to be
                    paid over an agreed-upon timeframe between Jump Loans and
                    the borrower.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  How can I report issues with my line of credit to Jump Loans?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    You may call one of our customer service team members at
                    (850) 990-JUMP or email us at <a>support@jumploans.com.</a>{" "}
                    Our business hours are Monday through Friday, from 9 a.m. to
                    5 p.m. EST.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  How do I qualify for a line of credit in Alabama?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    To qualify for a line of credit in Alabama, you must:
                    <ul>
                      <li>Be at least 18 years-old</li>
                      <li>
                        Be a United States citizen or a permanent resident
                      </li>
                      <li>Actively reside in Alabama</li>
                      <li>Have an active bank account</li>
                      <li>Have a regular source of income</li>
                      <li>
                        Have a valid contact number and an active email address
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  Is this a payday loan?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    This is not a payday loan. A payday loan is a short-term
                    loan that you can pay back in full on your next payday. Jump
                    Loans only offers Alabama lines of credit, which means we
                    don’t require you to make a lump-sum payment on your next
                    payday. Instead, this is an open-ended loan on which you are
                    only charged interest for the amount you use.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  Can I get same-day funding?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    If you’re approved, your funds will be automatically
                    deposited into your bank account typically within 48 hours
                    after we get a draw request from you.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>{" "}
        </Row>{" "}
      </Container>{" "}
    </Container>{" "}
  </Container>
)

export default SecondPage
